import React, { useLayoutEffect } from 'react'
import { graphql } from 'gatsby'
import { GridContainer, Layout, PageHeader } from '@ggs/components/layout'
import { Button } from '@ggs/gatsby/components/nav'
import { ImageCta, Quote } from '@ggs/components/paragraphs'
import { Carousel } from '@ggs/components/banners'
import reframe from 'reframe.js'

// Assets.
import './Careers.scss'
import { PageBackgroundVariant } from '@ggs/components/flare/PageBackgroundAccent'
import Box from '@mui/material/Box'
import colors from '@ggs/styles/theme/colors'
import { breakpoints, rem } from '@ggs/styles'
import { HomepageStackedContent } from '../Homepage/HomepageStackedContent'
// @ts-ignore
const Careers = ({ queries: { site } = {}, ...props }) => {
  const pageContext = props?.pageContext || {}
  const { content, hero, introCta } = pageContext?.data || {}
  useLayoutEffect(() => {
    reframe('iframe')
  })

  const renderCarousel = (/** @type {JSX.IntrinsicAttributes & { [x: string]: any; }} */ testimonials) => {
    const carousel = {
      variant: Carousel.variant.smallQuotes,
      title: testimonials?.title,
      submitForm: testimonials?.submitForm,
      carouselItems: testimonials?.quotes?.map((/** @type {any} */ quote) => ({
        ...(quote || {}),
        variant: Quote.variant.small,
      })) || [],
    }
    return <Carousel key={testimonials.id} {...carousel} />
  }

  const pageSx = {
    '.homepage-heroes, .product-grid': {
      backgroundColor: colors.ultraLightBlue,
    },
    '.highlight-cta': {
      backgroundColor: colors.white,
    },
    '.grid-container': {
      maxWidth: '100%',
      '.grid-container-inner': {
        maxWidth: rem(breakpoints.xl),
        '.grid-container, .grid-container-inner': {
          maxWidth: '100%',
          m: 0,
          p: 0,
        },
      },
    },
    '.paragraph-content__item': {
      margin: '0 auto',
      alignItems: 'center',
      flex: 'wrap',
      justifyContent: 'center',
      width: '100%',
      textAlign: 'center'
    }
  }

  return (
    <>
      <Layout
        className="homepage"
        site={site}
        pageContext={pageContext}
        backgroundVariant={PageBackgroundVariant.blue}
        sx={{ p: 0 }}
      >
        <Box sx={pageSx}>
          {hero && (
            <>
              <PageHeader title={hero.title} subtitle={hero.subtitle} className='homepage-heroes'>
                {hero?.link?.url && (
                  <div className="careers__hero-button">
                    <Button link={hero.link}/>
                  </div>
                )}
              </PageHeader>
            </>
          )}
          {introCta && (
            <ImageCta {...introCta} />
          )}
          <article>
            <GridContainer>
              <HomepageStackedContent
                sx={{
                  'title__subtitle': {
                    maxWidth: '640px',
                    margin: '0 auto'
                  }
                }}
                content={
                  content.filter((/** @type {JSX.IntrinsicAttributes & { [x: string]: any; }} */ item) =>
                    item?.type && item?.type !== 'Testimonials' ? item : null)
                }
                onUk={false}
              />
            </GridContainer>
            {content.map((/** @type {JSX.IntrinsicAttributes & { [x: string]: any; }} */ item) =>
              item?.type && item?.type === 'Testimonials' ? (
                renderCarousel(item)
              ) : null)}
          </article>
        </Box>
      </Layout>
    </>
  )
}

export const query = graphql`
  query careersPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Careers
