import { Stack } from '@mui/material'
import { Component } from '@ggs/gatsby/components'
import { uniqueId } from 'lodash'
import React from 'react'
import ProductGridUkHomepage from '@ggs/components/paragraphs/ProductGrid/ProductGridUkHomepage'

export const HomepageStackedContent = ({
  content,
  sx = null,
  spacing = null,
  onUk = true,
  children,
}) => {
  return Array.isArray(content) ? (
    <Stack className="paragraph-content" sx={sx} spacing={spacing}>
      {content.map((item) => {
        // TODO: this is a temp workaround issue w/ UK product grid.
        if (onUk && item?.type === 'ProductGrid') {
          return (
            <Component
              key={uniqueId(`paragraph_content_${item?.id}`)}
              wrapperClassName={'paragraph-content__item'}
              {...item}
              type={'ProductGridUkHomepage'}
            />
          )
        } else if (item?.type === 'ProductGrid') {
          return (
            <Component
              key={uniqueId(`paragraph_content_${item?.id}`)}
              wrapperClassName={'paragraph-content__item'}
              type={item.type}
              {...item}
            />
          )
        } else if (item?.type) {
          return (
            <Component
              key={uniqueId(`paragraph_content_${item?.id}`)}
              wrapperClassName={'paragraph-content__item'}
              type={item.type}
              {...item}
            />
          )
        } else {
          console.log('item', item)
          return null
        }
      })}
      {children}
    </Stack>
  ) : null
}
